<template>
    <section class="page-call-2-action">
        <div class="page-call-2-action__container">
            <h2 class="page-call-2-action__title">
                <span class="page-call-2-action__lead">{{ lead }}</span> {{ title }}
            </h2>
            <router-link class="page-call-2-action__link" to="/soknad">{{ link }}</router-link>
        </div>
    </section>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';

export default defineComponent({
    setup: () => ({
        title: computed(() => translate('page', 'home', 'position', 'title')),
        lead: computed(() => translate('page', 'home', 'position', 'lead')),
        link: computed(() => translate('page', 'home', 'position', 'link')),
    }),
});
</script>

<style lang="scss">
.page-call-2-action {
    position: relative;
    z-index: 1;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    z-index: 0;
    @include breakpoint(medium-large) {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
    }
    &:before {
        content: '';
        z-index: -1;
        @include position-corner;
        position: absolute;
        background: url('/bg.jpg') top no-repeat;
        background-size: 500%;
        @include breakpoint(medium-large) {
            background-size: cover;
        }
    }
    &:after {
        content: '';
        z-index: -1;
        right: 0;
        top: 0;
        position: absolute;
        width: 45%;
        padding-bottom: 45%;
        background-image: url('/svg/2.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__container {
        display: flex;
        @include container;
        flex-flow: column;
        align-items: center;
    }
    &__title {
        font-size: 1.5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
        color: #fff;
        @include breakpoint(medium-large) {
            font-size: 2.5rem;
        }
    }
    &__lead {
        background: var(--secondary);
        color: #fff;
        display: block;
        font-size: 1rem;
        padding: 0.625rem;
        text-transform: uppercase;
        margin-bottom: 1.25rem;
    }
    &__link {
        color: #fff;
        margin-top: 2.5rem;
        font-size: 1rem;
        @include btn-link;
        padding: 0.625em 1.25em;
        @include breakpoint(medium-large) {
            font-size: 1.25rem;
        }
    }
}
</style>
