
import { computed, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';

export default defineComponent({
    setup: () => ({
        title: computed(() => translate('page', 'home', 'position', 'title')),
        lead: computed(() => translate('page', 'home', 'position', 'lead')),
        link: computed(() => translate('page', 'home', 'position', 'link')),
    }),
});
